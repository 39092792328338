import { Skeleton } from 'antd';
import { twMerge } from 'tailwind-merge';

export const UserAvatar = ({
    onClick = undefined,
    withStories = false,
    withStoriesNotSeen = false,
    agent,
    className = ''
}) => {
    if (!agent) return <Skeleton className={twMerge('h-12 w-12 rounded-full lg:h-16 lg:w-16', className)} />;
    const level = agent?.level;
    const fullName = `${agent?.firstName} ${agent?.lastName}`;
    return (
        <div
            onClick={onClick}
            className={twMerge(
                'relative flex h-12 w-12 items-center justify-center lg:h-16 lg:w-16',
                withStories && 'cursor-pointer',
                className
            )}
        >
            {withStories ? (
                withStoriesNotSeen ? (
                    <img
                        src="/icons/loyalty/story.png"
                        className="absolute left-1/2 top-1/2 z-30 h-[81%] w-[81%] -translate-x-1/2 -translate-y-1/2 transform object-contain"
                        alt="story"
                    />
                ) : (
                    <img
                        src="/icons/loyalty/story_seen.png"
                        className="absolute left-1/2 top-1/2 z-30 h-[81%] w-[81%] -translate-x-1/2 -translate-y-1/2 transform"
                        alt="seen"
                    />
                )
            ) : null}
            {level ? (
                <img
                    src={`/icons/loyalty/${level}.png`}
                    alt="level"
                    className="absolute left-0 top-0 z-30 h-full w-full"
                />
            ) : null}
            <img
                width={128}
                height={128}
                src={
                    agent.profilePicture
                        ? `${agent.profilePicture}?reso=128x128&fit=${agent.uid === 'all' ? 'contain' : 'cover'}`
                        : `https://ui-avatars.com/api/?name=${fullName}`
                }
                alt={fullName}
                className="absolute left-1/2 top-1/2 z-10 h-[85%] w-[85%] shrink-0 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white object-cover"
            />
        </div>
    );
};
