export const HomeIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-colors duration-300"
        >
            <path
                d="M11.6235 12.008H6.67425C6.40333 12.008 6.17866 11.7833 6.17866 11.5124V6.56297C6.17866 6.29205 6.40333 6.06738 6.67425 6.06738H11.6235C11.8944 6.06738 12.1191 6.29205 12.1191 6.56297V11.5124C12.1191 11.7833 11.8944 12.008 11.6235 12.008ZM7.16984 11.0168H11.1279V7.05856H7.16984V11.0168Z"
                fill={color}
            />
            <path
                d="M18.8261 12.008H13.8768C13.6059 12.008 13.3813 11.7833 13.3813 11.5124V6.56297C13.3813 6.29205 13.6059 6.06738 13.8768 6.06738H18.8261C19.097 6.06738 19.3217 6.29205 19.3217 6.56297V11.5124C19.3217 11.7833 19.097 12.008 18.8261 12.008ZM14.3724 11.0168H18.3305V7.05856H14.3724V11.0168Z"
                fill={color}
            />
            <path
                d="M11.6235 19.1115H6.6742C6.40328 19.1115 6.17861 18.8868 6.17861 18.6159V13.6665C6.17861 13.3956 6.40328 13.1709 6.6742 13.1709H11.6235C11.8944 13.1709 12.119 13.3956 12.119 13.6665V18.6159C12.119 18.8868 11.8944 19.1115 11.6235 19.1115ZM7.16979 18.1203H11.1279V14.1621H7.16979V18.1203Z"
                fill={color}
            />
            <path
                d="M18.8261 19.1115H13.8768C13.6059 19.1115 13.3813 18.8868 13.3813 18.6159V13.6665C13.3813 13.3956 13.6059 13.1709 13.8768 13.1709H18.8261C19.097 13.1709 19.3217 13.3956 19.3217 13.6665V18.6159C19.3217 18.8868 19.097 19.1115 18.8261 19.1115ZM14.3724 18.1203H18.3305V14.1621H14.3724V18.1203Z"
                fill={color}
            />
        </svg>
    );
};

export const PropertiesIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-colors duration-300"
        >
            <path
                d="M3.07905 12.1596L12.2791 3.03955"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2792 3.03955L21.1593 11.9995"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3994 10.6396V19.7597C19.3994 20.4797 18.8394 21.1196 18.0394 21.1196H6.1994C5.4794 21.1196 4.8394 20.5597 4.8394 19.7597V10.7996"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ContactsIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-colors duration-300"
        >
            <path
                d="M3.07925 18.0796C3.07925 14.1596 6.19924 11.0396 10.1192 11.0396"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.3593 11.1995C8.03925 11.1995 6.27925 9.35963 6.27925 7.11963C6.27925 4.79963 8.11925 3.03955 10.3593 3.03955C11.3193 3.03955 12.2792 3.43963 12.9992 3.91963"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.4395 14.0799C16.6928 14.0799 18.5195 12.2531 18.5195 9.9998C18.5195 7.74648 16.6928 5.91992 14.4395 5.91992C12.1862 5.91992 10.3595 7.74648 10.3595 9.9998C10.3595 12.2531 12.1862 14.0799 14.4395 14.0799Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.2396 21.04C7.2396 17.12 10.3596 14 14.2796 14"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.3593 11.1995C8.11925 11.1995 6.27925 9.35963 6.27925 7.11963C6.27925 4.87963 8.11925 3.03955 10.3593 3.03955C12.0393 3.03955 13.4792 4.07963 14.1192 5.51963"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.07925 18.1597C3.07925 14.2397 6.19924 11.1196 10.1192 11.1196"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const SearchesIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-colors duration-300"
        >
            <path
                d="M12.1182 18.1595C15.4761 18.1595 18.1982 15.4374 18.1982 12.0795C18.1982 8.72163 15.4761 5.99951 12.1182 5.99951C8.76035 5.99951 6.03823 8.72163 6.03823 12.0795C6.03823 15.4374 8.76035 18.1595 12.1182 18.1595Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1182 13.7555C13.0439 13.7555 13.7943 13.0051 13.7943 12.0794C13.7943 11.1537 13.0439 10.4033 12.1182 10.4033C11.1925 10.4033 10.4421 11.1537 10.4421 12.0794C10.4421 13.0051 11.1925 13.7555 12.1182 13.7555Z"
                fill={color}
            />
            <path
                d="M21.1582 12.0796H18.1982"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.87834 12.0796H3.15835"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1987 18.1595C15.5566 18.1595 18.2787 15.4374 18.2787 12.0795C18.2787 8.72163 15.5566 5.99951 12.1987 5.99951C8.84081 5.99951 6.1187 8.72163 6.1187 12.0795C6.1187 15.4374 8.84081 18.1595 12.1987 18.1595Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1187 21.1192V18.1592"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1187 5.83963V3.11963"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const TasksIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-colors duration-300"
        >
            <path
                d="M6.27905 7.99951H13.399"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.27905 11.5195H11.2391"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.0792 12.3195V7.99951"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.3591 3.11963H4.27905C3.63905 3.11963 3.07905 3.59967 3.07905 4.15967V10.2396V20.1597C3.07905 20.7197 3.63905 21.1996 4.27905 21.1996H4.91907H12.1191"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.3595 3.11963V6.79961C16.3595 7.43961 16.8395 7.99961 17.4795 7.99961H21.0795L16.3595 3.11963Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.2394 21.0394C19.3602 21.0394 21.0794 19.3201 21.0794 17.1993C21.0794 15.0785 19.3602 13.3594 17.2394 13.3594C15.1186 13.3594 13.3994 15.0785 13.3994 17.1993C13.3994 19.3201 15.1186 21.0394 17.2394 21.0394Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.7191 17.279L16.9191 18.479L18.6791 15.999"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const PortfolioIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-colors duration-300"
        >
            <path
                d="M8.59917 14.9591L14.9192 8.71924L21.0792 14.7991"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.7993 13.8394V20.1593C19.7993 20.6393 19.3193 21.1194 18.8393 21.1194H10.8393C10.3593 21.1194 9.87925 20.6393 9.87925 20.1593V13.8394"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.9591 21.0396H3.95908C3.47908 21.0396 3.07905 20.5595 3.07905 19.9995V3.99951C3.07905 3.43951 3.47908 2.95947 3.95908 2.95947H12.4391C12.9191 2.95947 13.3191 3.43951 13.3191 3.99951V10.0796"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.75933 6.07959H6.99932"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.75933 8.79932H6.99932"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.39917 5.91943H11.7192"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.39917 8.71924H11.7192"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.75933 11.5195H6.99932"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.39917 11.3594H11.7192"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.1593 21.039V17.3589C13.1593 16.8789 13.6393 16.3989 14.1193 16.3989H15.7993C16.2793 16.3989 16.7593 16.8789 16.7593 17.3589V21.039"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ProductionsIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';

    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-colors duration-300"
        >
            <path
                d="M12.199 10.8794V15.9994"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.47905 13.4399H14.7591"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.5992 21.0398H5.55925C4.19925 21.0398 3.07925 19.9198 3.07925 18.5598V8.23984C3.07925 6.87984 4.19925 5.75977 5.55925 5.75977H18.5992C19.9592 5.75977 21.0792 6.87984 21.0792 8.23984V18.5598C21.0792 19.9998 20.0392 21.0398 18.5992 21.0398Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.4394 5.75947V5.35947C8.4394 4.07947 9.4794 3.03955 10.7594 3.03955H13.2394C14.5194 3.03955 15.5594 4.07947 15.5594 5.35947V5.75947"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const OperationsIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            className="transition-colors duration-300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.2785 9.59897C14.2785 9.59897 13.4784 8.479 12.1984 8.479C11.0784 8.479 10.1185 9.03898 10.1185 10.159C10.1185 11.279 11.1584 11.759 12.3584 12.079C13.3984 12.319 14.3584 12.959 14.3584 13.999C14.3584 15.039 13.2385 15.599 12.1185 15.599C10.5985 15.599 9.87847 14.479 9.87847 14.479"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2785 8.47939V7.35938"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.2785 16.6394V15.8394"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1187 21.0396C17.0672 21.0396 21.0788 17.0281 21.0788 12.0796C21.0788 7.13117 17.0672 3.11963 12.1187 3.11963C7.17028 3.11963 3.15874 7.13117 3.15874 12.0796C3.15874 17.0281 7.17028 21.0396 12.1187 21.0396Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const MarketingIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            className="transition-colors duration-300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.0791 21.0397L8.59907 16.4797H6.59907C5.47907 16.4797 4.67905 15.5996 4.67905 14.5596V9.67973C4.67905 8.55973 5.55907 7.75961 6.59907 7.75961H8.59907L19.0791 3.19965C19.3191 3.03965 19.6391 3.19969 19.6391 3.43969V11.9196V20.7196C19.6391 21.0396 19.3191 21.1997 19.0791 21.0397Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1192 18.1594C12.1192 19.1194 11.3192 19.8395 10.4392 19.8395C9.47921 19.8395 8.75923 19.0394 8.75923 18.1594V16.4795"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const StatsIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';

    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            className="transition-colors duration-300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.07905 3.03955V21.0396H21.0791"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.5591 8.64014C20.3986 8.64014 21.0791 7.95959 21.0791 7.12012C21.0791 6.28064 20.3986 5.6001 19.5591 5.6001C18.7196 5.6001 18.0391 6.28064 18.0391 7.12012C18.0391 7.95959 18.7196 8.64014 19.5591 8.64014Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3993 13.6L14.1993 17.44L18.9193 8.47998"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.59927 17.44L9.63926 13.52"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5193 13.9199C11.3587 13.9199 12.0393 13.2393 12.0393 12.3999C12.0393 11.5604 11.3587 10.8799 10.5193 10.8799C9.6798 10.8799 8.99927 11.5604 8.99927 12.3999C8.99927 13.2393 9.6798 13.9199 10.5193 13.9199Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const AcademyIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            className="transition-colors duration-300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5.70015 4.11768V19.788L12.4295 14.7308" stroke={color} />
            <path d="M18.5048 4.11768V19.788L11.7755 14.7308" stroke={color} />
        </svg>
    );
};

export const LegalsIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg
            width="25"
            height="25"
            className="transition-colors duration-300"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5187 6.79961L13.7987 3.51953L20.7587 10.3195L17.3987 13.5996L10.5187 6.79961Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.1587 9.91943L16.9187 14.1595"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.2786 3.03955L10.0386 7.27939"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.0385 10.2397L3.15854 21.0397"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.7986 21.0397H12.4386V18.3196C12.4386 17.6796 12.9186 17.1997 13.5586 17.1997H18.5986C19.2386 17.1997 19.7186 17.6796 19.7186 18.3196L19.7986 21.0397Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.2386 21.0396H21.0786"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const HelpIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.22272 21.1193H5.06273C4.02273 21.1193 3.14272 20.2393 3.14272 19.1993V14.2393C3.14272 13.1993 4.02273 12.3193 5.06273 12.3193H5.22272C6.26272 12.3193 7.14273 13.1993 7.14273 14.2393V19.1993C7.14273 20.2393 6.26272 21.1193 5.22272 21.1193Z"
                stroke="#212322"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3028 21.1193H19.1428C18.1028 21.1193 17.2228 20.2393 17.2228 19.1993V14.2393C17.2228 13.1993 18.1028 12.3193 19.1428 12.3193H19.3028C20.3428 12.3193 21.2228 13.1993 21.2228 14.2393V19.1993C21.1428 20.2393 20.3428 21.1193 19.3028 21.1193Z"
                stroke="#212322"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.1428 14.6396V8.79961C21.1428 5.67961 18.5828 3.11963 15.4628 3.11963H8.9028C5.7828 3.11963 3.2228 5.67961 3.2228 8.79961V16.3996"
                stroke="#212322"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const CalendarIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.8704 5.19971H5.35037C4.15037 5.19971 3.11035 6.15969 3.11035 7.35969V18.9597C3.11035 20.2397 4.15037 21.1197 5.35037 21.1197H18.8704C20.0704 21.1197 21.1104 20.1597 21.1104 18.9597V7.35969C21.1104 6.15969 20.1504 5.19971 18.8704 5.19971Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.71035 7.99963V3.11963"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9104 7.99963V3.11963"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.79043 10.1592L19.5104 10.2392"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ChatIcon = ({ active, className }: { active?: boolean; className?: string }) => {
    const color = active ? '#FFF' : '#212322';

    return (
        <svg
            className={className}
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.1504 21.0395C17.0989 21.0395 21.1104 17.0281 21.1104 12.0796C21.1104 7.13112 17.0989 3.11963 12.1504 3.11963C7.20197 3.11963 3.19043 7.13112 3.19043 12.0796C3.19043 14.5857 4.21934 16.8515 5.87777 18.4776L4.38048 21.4993L10.3674 21.0395H12.1504Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="7.55201" cy="12.0796" r="0.930716" fill={color} />
            <circle cx="12.1504" cy="12.0796" r="0.930716" fill={color} />
            <circle cx="16.7489" cy="12.0796" r="0.930716" fill={color} />
        </svg>
    );
};

export const NotificationIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.19043 19.1196H21.1904"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0705 3.11963V5.59963"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.1504 19.1196C14.1504 20.2396 13.2704 21.1196 12.1504 21.1196C11.0304 21.1196 10.1504 20.2396 10.1504 19.1196"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.6304 19.1196H5.75039V11.9996C5.75039 8.39961 8.63039 5.59961 12.1504 5.59961C15.7504 5.59961 18.5504 8.47961 18.5504 11.9996L18.6304 19.1196Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const CommunityIcon = ({ active }) => {
    const color = active ? '#FFF' : '#212322';
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.7105 21.0397L7.83056 16.4797H5.83056C4.71056 16.4797 3.91055 15.5996 3.91055 14.5596V9.67973C3.91055 8.55973 4.79056 7.75961 5.83056 7.75961H7.83056L16.7105 3.19965C16.9505 3.03965 17.2706 3.19969 17.2706 3.43969V11.9196V20.7196C17.2706 21.0396 16.9505 21.1997 16.7105 21.0397Z"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99052 16.4795V18.1594C7.99052 19.0394 8.7105 19.8395 9.6705 19.8395C10.3208 19.8395 10.9274 19.4462 11.1998 18.8536"
                stroke={color}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const MenuIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.3449 16.0328H4.55615"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3449 10.2998H4.55615"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3449 4.83301H4.55615"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
