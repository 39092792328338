import { CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { ReactNode } from 'react';
import useIsMobile from '../../hooks/isMobile';

export interface PulppoDrawerProps {
    visible: boolean;
    closable?: boolean;
    onClose?: () => void;
    children?: ReactNode;
    extra?: ReactNode;
    inline?: ReactNode;
    className?: string;
    title?: string | ReactNode;
    zIndex?: number;
    bodyStyle?: any;
    headerStyle?: any;
    push?: boolean;
    width?: number | string;
}

export const PulppoDrawer = ({
    visible,
    onClose,
    push = true,
    className = '',
    closable = true,
    inline = null,
    children = <></>,
    extra = <></>,
    width = 500,
    title = ' ',
    bodyStyle = {},
    zIndex = 0,
    headerStyle = {}
}: PulppoDrawerProps) => {
    const isMobile = useIsMobile();

    return (
        <Drawer
            push={push}
            zIndex={zIndex}
            placement={isMobile ? 'bottom' : 'right'}
            onClose={() => onClose && onClose()}
            className={`overscroll-contain ${className}`}
            title={title ? <p className="text-base font-medium">{title}</p> : null}
            width={inline ? 2 * +width : width}
            closable={false}
            height={'100%'}
            autoFocus={false}
            headerStyle={headerStyle}
            destroyOnClose
            extra={
                closable ? (
                    <div className="flex items-center">
                        {extra}
                        <CloseOutlined
                            width={34}
                            className="ml-2 rounded-none bg-black p-1 text-white"
                            onClick={() => onClose && onClose()}
                        />
                    </div>
                ) : null
            }
            open={visible}
            bodyStyle={bodyStyle}
        >
            {inline ? (
                <div className="flex h-full">
                    <div className="h-full flex-1 p-4">{inline}</div>
                    <div className="h-full flex-1">{children}</div>
                </div>
            ) : (
                children
            )}
        </Drawer>
    );
};
