import { Spin } from 'antd';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const PulppoLoader = ({
    loading,
    className = '',
    children = <></>
}: {
    loading: boolean;
    className?: string;
    children?: ReactNode;
}) => {
    return (
        <Spin
            className={className}
            wrapperClassName={className}
            spinning={loading}
            indicator={
                <div className="absolute left-0 top-0 z-50 m-0 flex h-full w-full items-center justify-center">
                    <div className="relative flex items-center justify-center pt-20">
                        <img
                            src={process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO || '/pulppo.png'}
                            alt="pulppo-loader"
                            style={{
                                objectFit: 'cover',
                                width: '60px',
                                height: '60px'
                            }}
                        />
                        <div className="loader absolute " />
                    </div>
                </div>
            }
        >
            {children}
        </Spin>
    );
};

export const PulppoNewLoader = ({
    loading,
    className = '',
    children = <></>
}: {
    loading: boolean;
    className?: string;
    children?: ReactNode;
}) => {
    // if (!loading) return children;
    return (
        <div className={twMerge('relative', className)}>
            {loading && (
                <>
                    <div className="absolute left-0 top-0 z-50 m-0 flex h-full w-full bg-white opacity-60"></div>
                    <div className="absolute left-0 top-0 z-50 m-0 flex h-full w-full items-center justify-center">
                        <div className="relative flex items-center justify-center pt-20">
                            <img
                                src={process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO || '/pulppo.png'}
                                alt="pulppo-loader"
                                style={{
                                    objectFit: 'cover',
                                    width: '60px',
                                    height: '60px'
                                }}
                            />
                            <div className="loader absolute " />
                        </div>
                    </div>
                </>
            )}
            {children}
        </div>
    );
};
