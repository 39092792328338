import { initializeApp, getApps, FirebaseApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { Messaging, getMessaging, isSupported } from 'firebase/messaging';
import { ImageUploadProviders, ImageUploaderService } from '@pulppo/image-uploader';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const apps = getApps();
let provider: GoogleAuthProvider;
let storage;
let app: FirebaseApp;
if (!apps.length && typeof window !== 'undefined') {
    const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE || '{}');

    // Initialize Firebase
    app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
    provider = new GoogleAuthProvider();
    provider.setDefaultLanguage('es');
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('https://www.googleapis.com/auth/calendar');
    provider.setCustomParameters({
        hd: process.env.NEXT_PUBLIC_HOSTED_DOMAIN || 'pulppo.com'
    });
    storage = getStorage(app);

    ImageUploaderService.config(ImageUploadProviders.Google, {
        // storage,
        baseUrl: process.env.NEXT_PUBLIC_IMAGE_URL || `https://images.pulppo.com`,
        ...firebaseConfig
    });
}

const firebaseConfig = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE || '{}');
export const FirestoreApp =
    firebaseConfig?.appId === app?.options?.appId ? app : initializeApp(firebaseConfig, 'development');
export const db = getFirestore(FirestoreApp); // TODO: Modularize this

export const auth = getAuth(FirestoreApp);
let messaging: Messaging;

isSupported().then((supported) => {
    console.log('Supported', supported);
    if (supported) {
        messaging = getMessaging(FirestoreApp);
    }
});

export { provider, storage, app, messaging };
