import { useRouter } from 'next/router';
import useIsMobile from '../../hooks/isMobile';
import useUser from '../../hooks/useUser';
import { CalendarIcon, ChatIcon, CommunityIcon, HomeIcon, MenuIcon, NotificationIcon, PropertiesIcon } from './Icons';
import Link from 'next/link';
import { publicRoutes } from './routes';
import { PulppoDrawer } from '../Elements/PulppoDrawer';
import { LeftSidebarContent } from './LeftSidebar';
import { useState } from 'react';
import { Community } from '../Community/Community';
import { Notifications } from '../Notifications/Notifications';
import { NovuProvider, useUnreadCount } from '@novu/notification-center';
import useSWR from 'swr';
import { api_url, fetcher } from '../../helpers/fetcher';
import { Badge } from 'antd';
import { twMerge } from 'tailwind-merge';

const MenuItem = ({ Icon, title, active, href = '', onClick = undefined }) => {
    const Component = href ? Link : 'div';
    return (
        <Component href={href} onClick={onClick}>
            <div
                className={`flex h-14 w-14 shrink-0 flex-col items-center gap-1 py-2 ${active ? 'border-t-4 border-solid border-primary-color' : ''}`}
            >
                <Icon active={true} />
                <p className="text-xs text-white">{title}</p>
            </div>
        </Component>
    );
};

const MobileRightSidebarContent = () => {
    const router = useRouter();
    const [showMenu, setShowMenu] = useState(false);
    const [showCommunity, setShowCommunity] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const { uid, featureCallCenter } = useUser((u) => ({
        uid: u.user?.uid,
        featureCallCenter: u.user?.features?.callCenter
    }));

    const count = useUnreadCount();

    const { data: posts } = useSWR(uid && `${api_url}/post/count`, fetcher, {
        fallbackData: 0,
        refreshInterval: 10 * 60 * 1e3
    });

    if (publicRoutes.includes(router.pathname)) return <></>;

    return (
        <>
            {router.pathname === '/' && (
                <div
                    className={twMerge(
                        'translate fixed bottom-[4.5rem] left-1/2 mx-auto mb-2 flex -translate-x-1/2 transform items-center gap-4 rounded-full bg-pulppo-primary-black px-3 py-2',
                        featureCallCenter && 'bottom-28'
                    )}
                >
                    <div className="px-3" onClick={() => setShowNotifications(true)}>
                        <Badge count={count.data?.count} color="#000" size="small">
                            <NotificationIcon active={true} />
                        </Badge>
                    </div>
                    <div className="px-3" onClick={() => setShowCommunity(true)}>
                        <Badge count={posts} color="#000" size="small">
                            <CommunityIcon active={true} />
                        </Badge>
                    </div>
                </div>
            )}
            <div className="flex h-footer-height w-full items-center justify-evenly bg-pulppo-primary-black pb-4">
                <MenuItem
                    onClick={() => {
                        setShowMenu(true);
                    }}
                    active={showMenu}
                    Icon={MenuIcon}
                    title={'Menú'}
                />
                <MenuItem
                    href="/calendar"
                    active={router.pathname === '/calendar'}
                    Icon={CalendarIcon}
                    title={'Visitas'}
                />
                <MenuItem href="/" active={router.pathname === '/'} Icon={HomeIcon} title={'Inicio'} />
                <MenuItem
                    href="/search"
                    active={router.pathname === '/search'}
                    Icon={PropertiesIcon}
                    title={'Propiedades'}
                />
                <MenuItem href="/chats" active={router.pathname === '/chats'} Icon={ChatIcon} title={'Chat'} />
            </div>
            <PulppoDrawer
                title={null}
                bodyStyle={{ padding: 0, paddingBottom: '24px' }}
                onClose={() => setShowMenu(false)}
                visible={showMenu}
            >
                <LeftSidebarContent showAll={true} onClose={() => setShowMenu(false)} setShowDrawer={setShowMenu} />
            </PulppoDrawer>
            <PulppoDrawer
                title={' '}
                closable
                bodyStyle={{ padding: 0 }}
                onClose={() => setShowCommunity(false)}
                visible={showCommunity}
            >
                <Community />
            </PulppoDrawer>
            <PulppoDrawer
                title={' '}
                closable
                bodyStyle={{ padding: 0 }}
                onClose={() => setShowNotifications(false)}
                visible={showNotifications}
            >
                <Notifications />
            </PulppoDrawer>
        </>
    );
};

export const MobileRightSidebar = () => {
    const user = useUser((u) => u.user);
    const mobile = useIsMobile();
    if (process.env.NEXT_PUBLIC_NEW_HOME !== 'true' || !mobile) return <></>;

    return (
        <>
            <NovuProvider
                initialFetchingStrategy={{
                    fetchNotifications: true,
                    fetchUserPreferences: true,
                    fetchUnreadCount: true
                }}
                i18n="es"
                subscriberId={user?.uid}
                applicationIdentifier={process.env.NEXT_PUBLIC_NOVU_ID}
            >
                <MobileRightSidebarContent />
            </NovuProvider>
        </>
    );
};
