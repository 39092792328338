import { twMerge } from 'tailwind-merge';

interface Props {
    title: string;
    description: string;
    imageSrc?: string;
    className?: string;
}

export const EmptyState = ({ title, description, className, imageSrc }: Props) => {
    return (
        <div className={twMerge('mx-auto flex flex-1 flex-col items-center py-6', className)}>
            <img src={imageSrc || '/icons/not_found.png'} width={64} height={64} alt="Not found" />
            <p className="mt-4 text-center font-medium">{title}</p>
            <p className="mt-1 max-w-sm text-center text-sm text-pulppo-primary-gray">{description}</p>
        </div>
    );
};

export const EmptyStateType = ({ type }: { type: 'visit' }) => {
    if (type === 'visit') {
        return (
            <EmptyState
                title="No tienes visitas registradas"
                description="No hay visitas registradas para esta busqueda. Agenda una nueva visita o registra una visita que ya hayas realizado."
                imageSrc="/visits_empty.png"
            />
        );
    }
    return null;
};

export default EmptyState;
